import { render, staticRenderFns } from "./protoForm.vue?vue&type=template&id=16eb0756&scoped=true&"
import script from "./protoForm.vue?vue&type=script&lang=js&"
export * from "./protoForm.vue?vue&type=script&lang=js&"
import style0 from "./protoForm.vue?vue&type=style&index=0&id=16eb0756&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "16eb0756",
  null
  
)

export default component.exports