<template>
  <BaseForm style="width: 500px" ref="form" label-width="auto" :cols="option.baseFormField || []" :form="form">
  </BaseForm>
</template>

<script>
import { getOption } from '../const'

export default {
  props: {
    form: {
      type: Object,
      default: () => ({})
    },

    type: {
      type: String,
      default: 'default'
    }
  },

  computed: {
    option({ type }) {
      return getOption(type)
    }
  },

  methods: {
    async validate() {
      const { form } = this.$refs
      try {
        return await form.validate()
      } catch {
        return false
      }
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .el-form-item__label-wrap {
    margin-left: 0 !important;
  }
}
</style>